import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';

import { usePageContentId } from '@confluence/page-context';
import { useSearchSessionId } from '@confluence/search-session';
import { useFeatureCodesInURL } from '@confluence/feature-codes';

import SendTrackEvent from '../SendTrackEvent';

import { SendTrackViewPageEventQuery } from './SendTrackViewPageEventQuery.graphql.js';

const dayInMilliseconds = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
const NO_DATE_DEFINED = -1;
const ARCHIVED = 'archived';

export const _getDaysSincePastDate = (pastDateStr, currentDate = new Date()) => {
	const pastDate = new Date(pastDateStr);

	// days since creation
	return Math.round((currentDate.getTime() - pastDate.getTime()) / dayInMilliseconds);
};

const _getDaysSinceCreated = (node) => {
	const createdDateStr = node?.history?.createdDate;

	return createdDateStr ? _getDaysSincePastDate(createdDateStr) : NO_DATE_DEFINED;
};

const _getDaysSinceUpdated = (node) => {
	const lastUpdatedStr = node?.history?.lastUpdated?.when;

	return lastUpdatedStr ? _getDaysSincePastDate(lastUpdatedStr) : NO_DATE_DEFINED;
};

export const _isAfter3PM = (currentDate = new Date()) => {
	const hours = currentDate.getHours();
	return hours >= 15 && hours <= 23;
};

const SendTrackViewPageEvent = ({ pageType, source }) => {
	const [contentId] = usePageContentId();
	const [{ searchSessionId, additionalAnalytics }] = useSearchSessionId();
	const featureCodes = useFeatureCodesInURL(window.location.search);

	const { data, loading, error } = useQuery(SendTrackViewPageEventQuery, {
		variables: {
			contentId,
		},
	});

	const space = data?.content?.nodes?.[0]?.space;
	const fabricEditorSupported =
		data?.content?.nodes?.[0]?.metadata?.frontend?.fabricEditorSupported;
	const fabricEditorEligibility =
		data?.content?.nodes?.[0]?.metadata?.frontend?.fabricEditorEligibility;

	if (error || loading || !space) return null;

	return (
		<SendTrackEvent
			action="viewed"
			actionSubject="page"
			source={source}
			containerType="space"
			containerId={space.id}
			objectType="page"
			objectId={contentId}
			attributes={{
				pageType,
				daysSinceCreated: _getDaysSinceCreated(data.content.nodes[0]),
				daysSinceModified: _getDaysSinceUpdated(data.content.nodes[0]),
				searchReferrer: searchSessionId,
				...additionalAnalytics,
				isArchivedPage: data.content.nodes[0].status === ARCHIVED,
				viewedScreenAfter3PM: _isAfter3PM(),
				fabricEditorEligibility,
				fabricEditorSupported,
				...(featureCodes && featureCodes.from ? { from: featureCodes.from } : {}),
			}}
		/>
	);
};

SendTrackViewPageEvent.propTypes = {
	pageType: PropTypes.oneOf(['page', 'blogpost']),
	source: PropTypes.oneOf(['viewPageScreen', 'mobileWebView']),
};

SendTrackViewPageEvent.defaultProps = {
	source: 'viewPageScreen',
};

export default SendTrackViewPageEvent;
