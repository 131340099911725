import { useMemo } from 'react';

import FeatureGates from '@atlaskit/feature-gate-js-client';
import { fg } from '@atlaskit/platform-feature-flags';

import { useSessionData } from '@confluence/session-data';

import { editorFeatureFlags } from '../editorFeatureFlags';

export const useEditorFeatureFlags = () => {
	const { featureFlags } = useSessionData();
	return useMemo(
		() => ({
			...editorFeatureFlags(featureFlags),
			/**
			 * This feature flag has completed rollout in Confluence and is to be cleaned up from LD
			 * but cannot yet be cleaned up from editor component as pending rollout in other products first
			 */
			'lp-link-picker': true,
			'table-preserve-width': FeatureGates.getExperimentValue<boolean>(
				'platform_editor_table_preserve_width',
				'isEnabled',
				false,
			),
			'table-with-fixed-column-widths-option': FeatureGates.getExperimentValue<boolean>(
				'platform_editor_preserve_table_widths_part_two',
				'isEnabled',
				false,
			),
			'nested-expand-in-expand-ex': FeatureGates.getExperimentValue<boolean>(
				'platform_editor_nest_nested_expand_in_expand',
				'isEnabled',
				false,
			),
			'nest-media-and-codeblock-in-quote': FeatureGates.getExperimentValue<boolean>(
				'platform_editor_nest_media_and_codeblock_in_quotes',
				'isEnabled',
				false,
			),
			'renderer-tti-tracking': fg('renderer_tti_tracking_confluence'),
		}),
		[featureFlags],
	);
};
