import { useEffect, useState } from 'react';

import type { GetFeatureCodesResponse } from '.';
import { getFeatureCodes } from '.';

import { hideFeatureCodesQueryParam } from './featureCodes';

/**
 * Hook to extract feature codes from the URL and hide them from the URL
 *
 * @param queryParams - The query parameters from the URL
 * @returns The feature codes extracted from the URL
 */
export const useFeatureCodesInURL = (queryParams: string): GetFeatureCodesResponse => {
	const [featureCodes, setFeatureCodes] = useState<GetFeatureCodesResponse>({
		from: undefined,
	});

	useEffect(() => {
		if (queryParams) {
			const searchParams = new URLSearchParams(queryParams);
			setFeatureCodes(getFeatureCodes(searchParams));
			hideFeatureCodesQueryParam(searchParams);
		}
	}, [queryParams]);

	return featureCodes;
};
