/**
 * Feature attribution enum for tracking feature usage/attribution
 *
 * @note All feature codes are intended to be 8 characters or less in length
 *
 * @see https://hello.atlassian.net/wiki/x/lQxt9Q
 */
export enum FeatureCode {
	/** Confluence Home Feeds (Following and Popular) **/
	HOME_FEED = 'HOMEFEED',
	/** Confluence End of Page Recommendations (Related Pages) **/
	EOP_REC = 'EOPREC',
}

export type GetFeatureCodesResponse = { from: FeatureCode | undefined };

export const FEATURE_CODES_QUERY_PARAM = 'atl_f';
export const CONFLUENCE_INSIGHTS_FEATURE_CODES_FEATURE_GATE =
	'confluence_frontend_insights_feature_attribution';
