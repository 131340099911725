import { query, bestEffortTask } from '@confluence/query-preloader-tools';
import { getOnboardingStateGQL } from '@confluence/onboarding-helpers/entry-points/getOnboardingStateGQL';
import { ONBOARDING_BLOG_TREE } from '@confluence/onboarding-helpers/entry-points/constants/onboarding-state-constants';

export const preloadBlogsToggledOffChangeboarding = () => {
	return bestEffortTask('DisabledBlogsOnboardingStateQuery', () =>
		query({
			query: getOnboardingStateGQL,
			variables: {
				key: Object.values(ONBOARDING_BLOG_TREE),
			},
		}),
	);
};
