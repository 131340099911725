import { query } from '@confluence/query-preloader-tools';

import { BlogTree } from './BlogTreeQuery.graphql';
import { PAGE_SIZE, BLOG_STATUSES } from './treeParameters';

export const preloadBlogTree = ({ spaceKey }) => {
	return query({
		query: BlogTree,
		variables: {
			spaceKey,
			first: PAGE_SIZE,
			statuses: BLOG_STATUSES,
		},
	});
};
